import React, { useEffect, useState } from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/v1/index";
import { getMethod, postMethod } from "../core/service/common.api";
import Sidebar from "../pages/Sidebar";
import AfterloginHeader from "../pages/AfterloginHeader";
import moment from "moment";
import Pagination from "react-js-pagination";
import useStateRef from "react-usestateref";
const Home = () => {
  const navigate = useNavigate();

  const recordPerPage = 5;
  const pageRange = 5;

  const [currentPage, setcurrentPage] = useState(1);
  const [perpage, setperpage] = useState(5);
  const [total, settotal] = useState(0);
  const [userloginlist, setuserloginlist] = useState([]);
  const [maleEmployes, setMaleEmployes, maleEmployesref] = useStateRef();
  const [femaleEmployes, setfeMaleEmployes, femaleEmployesref] = useStateRef();
  const [totalEmployes, settotalEmployes, totalEmployesref] = useStateRef();
  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      getuserlogin(1);
    } else {
      navigate("/login");
    }
    employe_details();
  }, []);

  const getuserlogin = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
    };
    var data = {
      apiUrl: apiService.login_history_hr,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      console.log(resp.data);
      setuserloginlist(resp.data.data);
      settotal(resp.data.total);
    }
  };
  const employe_details = async (pages) => {
    var data = {
      apiUrl: apiService.employe_details,
    };
    var resp = await postMethod(data);
    console.log(resp.data);

    settotalEmployes(resp.data.total);
    setMaleEmployes(resp.data.boys);
    setfeMaleEmployes(resp.data.girls);
  };
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setcurrentPage(pageNumber);
    getuserlogin(pageNumber);
  };

  return (
    <>
      <>
        {/* <Navbar /> */}
        <div className="css-1wr4jig">
          <div className="css-1o43t2y">
            <div className="css-1t8cbiy side">
              <Sidebar />
            </div>
            <div className="css-1h690ep content">
              <div className="content_dash_board">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <AfterloginHeader title="Dashboard" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-4 mb-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Total Employes</p>
                        <h3>
                          <span className="green">
                            {totalEmployesref.current}
                          </span>{" "}
                        </h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/employes.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Female</p>
                        <h3>
                          <span className="green">
                            {femaleEmployesref.current}
                          </span>{" "}
                        </h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/female.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Male</p>
                        <h3>
                          <span className="green">
                            {maleEmployesref.current}
                          </span>{" "}
                        </h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/maleimage.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <h4 className="admin_inner_title">Login History</h4>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="card_dash table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>S.no</th>
                            <th>Email</th>
                            <th>IP Address</th>
                            <th>Device</th>
                            <th>Time & Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userloginlist.length > 0 ? (
                            userloginlist.map((data, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{data.userName}</td>
                                  <td>{data.ipAddress}</td>
                                  <td>{data.platform}</td>
                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="12" className="text-center">
                                <img
                                  src="https://res.cloudinary.com/dpnvbcrn2/image/upload/v1697629974/Group_3_1_pdpo1e.png"
                                  class="nodata"
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {userloginlist.length > 0 ? (
                      <div class="pagination justify-content-end mt-4">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPage}
                          itemsCountPerPage={recordPerPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={pageRange}
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </>
    </>
  );
};

export default Home;
