import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";

import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography, 
    styled,
    ListItemButton,
    ListItemText,
} from '@mui/material';
// menu
import DrawerItem from './DrawerItem';
// rotas
import { NavLink } from 'react-router-dom';

import Logo from "../assets/newimage/Shatrilogo.png"

import { Link } from 'react-scroll';
// personalizacao
const StyledToolbar = styled(Toolbar) ({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")] : {
        display: "flex",
    },
}));

//rotas
const itemList = [
    {
      text: "Home",
      to: "/" 
    },
    {
      text: "About us",
      to: "/about"
    },
    {
        text: "Token",
        to: "/contact"
    },
    {
        text: "Roadmap",
        to: "#Roadmap"
    },
    {
        text: "Whitepaper",
        to: "/contact"
    },
    {
        text: "Contact us",
        to: "/contact"
    }
];


const Navbar = () => {
    
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      // Check the scroll position
      if (window.scrollY > 100) { // You can adjust the threshold as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
        <AppBar 
        component="nav" 
        position="sticky"
        sx={{ 
            backgroundColor: '#4253F0', 
        }}
        className={isScrolled ? 'Header_fixed' : 'height_header'}
        elevation={0}
        >
            <StyledToolbar>
                <div className='nav_link'>
                            <a href='' >
                            <img
                        src={require("../assets/summit/white_logo.png")}
                        className="logo"
                      />
                            
                            </a>
                </div>
                <Box sx={{display: { xs: 'block', sm: 'none' } }}>
                    <DrawerItem /> 
                </Box>
               
                <ListMenu className='menu_item_rght'>
                    <ListItem>
                    <Link
            to="home" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            Home
          </Link>
                    </ListItem>
                    <ListItem>
                    <Link
            to="about" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            About us
          </Link>
                    </ListItem>
                    <ListItem>
                    <Link
            to="Launchpad" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            Token
          </Link>
                    </ListItem>
                    <ListItem>
                    <Link
            to="Roadmap" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            Roadmap
          </Link>
                    </ListItem>
                    <ListItem>
                    <Link
            to="Team" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            Team
          </Link>
                    </ListItem>
                    <ListItem>
                    <Link
            to="Whitepaper" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            Whitepaper
          </Link>
                    </ListItem>
                    <ListItem>
                    <Link
            to="Contact" // Replace with the ID of the section you want to scroll to
            spy={true}
            smooth={true}
            duration={500} // Duration of the scroll animation in milliseconds
          >
            Contact us
          </Link>
                    </ListItem>
                </ListMenu>
                {/* <ListMenu className='menu_item_rght'>
                    {itemList.map( ( item ) => {
                        const { text } = item;
                        return(
                            <ListItem key={text}>
                                <ListItemButton component={NavLink} to={item.to}
                                sx={{
                                    color: '#fff',
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        color: '#1e2a5a',
                                    }
                                }}
                                >
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </ListMenu> */}
                
                <ListMenu className='omarided'>
                <NavLink to='/login' className="header_primery_butn">
                                      Login
                                    </NavLink>
                
                </ListMenu>

            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar;
