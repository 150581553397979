import React from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

//componentes
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer/Footer";
import Sidebar from "./Sidebar";
import AfterloginHeader from "./AfterloginHeader";

const Home = () => {
  return (
    <>
      <>
        {/* <Navbar /> */}
        <div className="css-1wr4jig">
          <div className="css-1o43t2y">
            <div className="css-1t8cbiy side">
              <Sidebar />
            </div>
            <div className="css-1h690ep content">
              <div className="content_dash_board">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <AfterloginHeader title="Portfolio" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-4 mb-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Today's Profit / Pecentage (%)</p>
                        <h3>
                          $31 <span className="green">3.1%</span>
                        </h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/investment.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Today loss</p>
                        <h3>$100</h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/loss.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Total Profit</p>
                        <h3>
                          $3.031 <span className="green">3.1%</span>
                        </h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/investment.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Total Deposit</p>
                        <h3>$3,000</h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/deposit.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Available Amount</p>
                        <h3>$3.031</h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/cash.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card_dash">
                      <div className="card_portfollio">
                        <p>Profit/loss</p>
                        <h3>+ $31</h3>
                      </div>
                      <div className="colrd_icon">
                        <img
                          src={require("../assets/summit/loss.png")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </>
    </>
  );
};

export default Home;
