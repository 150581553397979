import React, {useState, useEffect} from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import apiService from "../core/service/v1/index";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthorization} from "../core/service/axios";
import {getAuthToken, setAuthToken} from "../core/lib/localStorage";
import useStateRef from "react-usestateref";

//componentes
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer/Footer";
const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const inputValue = {
    email: "",
    password: "",
  };

  const [loginValue, setloginValue] = useState(inputValue);

  const [emailErr, setemailErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [validationerr, setvalidationerr, validationerrRef] = useStateRef({
    email: "Email is required",
  });
  const [buttonLoader, setbuttonLoader] = useState(false);

  const getValue = (e) => {
    const {name, value} = e.target;
    let formData = {...loginValue, ...{[name]: value}};
    setloginValue(formData);
  };

  const validation = () => {
    const error = {};
    if (loginValue.email == "") {
      setemailErr(true);
      error.email = "Email is required !";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(loginValue.email)
    ) {
      setemailErr(true);
      error.email = "Invalid Email Address !";
    } else if (loginValue.password == "") {
      setpasswordErr(true);
      error.password = "Password is required !";
    } 
    setvalidationerr(error);
  };

  const fromSubmit = async () => {
    validation();
    if (Object.keys(validationerrRef.current).length == 0) {
      var data = {
        apiUrl: apiService.login,
        payload: loginValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status == true) {
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfastatus);
          await setAuthorization(resp.token);
          setAuthToken(resp.token); 
          navigate("/dashboard");
          toast.success(resp.Message);
      } else {
        console.log(resp);
        toast.error(resp.Message);
      }
    }
  };


  return (
    <>
      {/* <Navbar /> */}

      <main className="login_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="form_right_section">
                <div>
                  <Link to="/">
                    <img
                      src={require("../assets/logo.png")}
                      className="fire_text"
                    />
                  </Link>
                 
                </div>
              
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login_form_section w-100">
                <div className="w-100">
                  <h2>Login</h2>
                  <div className="login_forom">
                    <div class="form-group">
                      <label for="email">Email address</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        onChange={getValue}
                        placeholder="Enter email address"
                      />
                      {emailErr == true ? (
                        <h6 className="text-danger">
                          {validationerrRef.current.email}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="form-group">
                      <div className="forget_link">
                        <label for="pwd">Password</label>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        name="password"
                        onChange={getValue}
                        id="pwd"
                        placeholder="Enter Password"
                      />
                      <div>
                        {passwordErr == true ? (
                          <h6 className="text-danger">
                            {validationerrRef.current.password}
                          </h6>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                
                    {buttonLoader == true ? (
                      <Button>Loading...</Button>
                    ) : (
                      <Button onClick={fromSubmit}>Login</Button>
                    )}
                  </div>
                </div>
                <p>2023 All rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
