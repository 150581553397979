import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
function Home() {
  const navigate = useNavigate();
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="sidebar_link ">
      <div className="menuee">
        <div className="logo_section">
          <a href="/">
            {" "}
            <img src="https://res.cloudinary.com/dpnvbcrn2/image/upload/v1685521189/beleaf_profile_lo5xha.png"className="" />
          </a>
        </div>

        <NavLink to="/history" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-profile-fill"></i>{" "}
            <div className="aherd_ineer">Employe Details</div>
          </div>
        </NavLink>
        <NavLink to="/employeform" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-article-fill"></i>{" "}
            <div className="aherd_ineer">Employe form</div>
          </div>
        </NavLink>
      </div>
      <div className="menuee">
        <NavLink to="/" className="css-z87e9z active radius_o">
          <div onClick={logout} className="css-10j588g ">
            {" "}
            <i class="ri-logout-box-r-fill"></i>
            <div className="aherd_ineer">Logout</div>
            <i class="ri-arrow-right-s-fill"></i>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Home;
