import React, { useEffect, useState } from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import Sidebar from "./Sidebar";
import AfterloginHeader from "./AfterloginHeader";
import { Dropdown, Search } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/service/axios";
import { getAuthToken, setAuthToken } from "../core/lib/localStorage";
import useStateRef from "react-usestateref";
import CsvDownloader from "react-csv-downloader";

const Home = () => {
  useEffect(() => {
    employeHistory(1);
  }, [0]);
  const navigate = useNavigate();

  const [employeData, setEmployeData] = useState([]);
  const [employeDataCsv, SetemployeDataCsv] = useState([]);

  const employeHistory = async (page) => {
    try {
      var obj = {
        page: page,
        perpage: 5,
      };
      var data = {
        apiUrl: apiService.get_employe_details,
        payload: obj,
      };
      var resp = await postMethod(data);
      setEmployeData(resp.data.data);
      setemployetotalPages(resp.data.total);
      // SetemployeDataCsv(resp.data.data)
    } catch (err) {}
  };
  const employerecordPerPage = 5;
  const employepageRange = 5;
  const [employetotalPage, setemployetotalPages] = useState(0);
  const [employecurrentPage, setemployecurrentPage] = useState(1);

  const employehandlePageChange = (pageNumber) => {
    employeHistory(pageNumber);
    setemployecurrentPage(pageNumber);
  };
  const deleteEmploye = async (item) => {
    try {
      var obj = {
        id: item._id,
      };
      var data = {
        apiUrl: apiService.delete_employe_details,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp) {
        toast.success(resp.Message);
        employeHistory(1);
      }
    } catch (err) {}
  };
  const editEmploye = async (item) => {
    navigate(`/employeform/${item._id}`);
  };
  const [search, setsearch] = useState();
  const searcharea = async (e) => {
    console.log(e.target.value);
    var obj = {
      searchelement: e.target.value,
    };
    var data = {
      apiUrl: apiService.search_employe_details,
      payload: obj,
    };
    var resp = await postMethod(data);
    setEmployeData(resp.data.data);
    setemployetotalPages(resp.data.total);
  };
  const addEmploye = () => {
    navigate("/employeform");
  };
  return (
    <>
      <>
        {/* <Navbar /> */}
        <div className="css-1wr4jig">
          <div className="css-1o43t2y">
            <div className="css-1t8cbiy side">
              <Sidebar />
            </div>
            <div className="css-1h690ep content">
              <div className="content_dash_board">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <AfterloginHeader title="EMPLOYEE DETAILS" />
                    </div>
                  </div>
                </div>

                <div className="row mt-4 ">
                  <div className="col-lg-12">
                    <div className="card_dash">
                      <div className="history_tab">
                        <ul className="nav nav-tabs">
                          <li className="active">
                            <a
                              data-toggle="tab"
                              href="#home"
                              className="active"
                            >
                              Employe Details{" "}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="tab-content">
                        <div id="home" className="tab-pane fade in active show">
                          <div className="row mt-3">
                            <div className="col-lg-12 mt-3 mb-3">
                              <div className="row date_frame">
                                <div className="col-lg-3">
                                  <div className="form-group">
                                    <label>Name </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={search}
                                      name="search"
                                      onChange={searcharea}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-9 ">
                                  <div
                                    className="colrd_icon "
                                    onClick={addEmploye}
                                  >
                                    <img
                                      src={require("../assets/addemploye1.png")}
                                      className="wifth"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="table-responsive">
                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th>S.No</th>
                                      <th>Employe Name</th>
                                      <th>Fathers name</th>
                                      <th>Date Of Join</th>
                                      <th>Employe Id</th>
                                      <th>Status</th>
                                      <th>Official Mail</th>
                                      <th>Personal Mail</th>
                                      <th>Official Number</th>
                                      <th>Emergency Number</th>
                                      <th>Salary</th>
                                      <th>Blood Group</th>
                                      <th>Address</th>
                                      <th>Action</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employeData && employeData.length > 0 ? (
                                      employeData.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              <p>{item.firstName}</p>
                                            </td>
                                            <td>
                                              <p>{item.fathersName}</p>
                                            </td>{" "}
                                            <td>
                                              <p>{item.dateofJoin}</p>
                                            </td>
                                            <td>
                                              <p>{item.employeID}</p>
                                            </td>
                                            <td>
                                              {item.status == "Active" ? (
                                                <p className="text-right success">
                                                  Active
                                                </p>
                                              ) : (
                                                <p className="text-right pending">
                                                  De Active{" "}
                                                </p>
                                              )}
                                            </td>
                                            <td>
                                              <p>{item.mail}</p>
                                            </td>
                                            <td>
                                              <p>{item.personalMail}</p>
                                            </td>
                                            <td>
                                              <p>{item.phone}</p>
                                            </td>
                                            <td>
                                              <p>{item.emergencyPhone}</p>
                                            </td>
                                            <td>
                                              <p>{item.salary}</p>
                                            </td>
                                            <td>
                                              <p>{item.bloodGroup}</p>
                                            </td>
                                            <td>
                                              <p>{item.address1}</p>
                                            </td>
                                            <td
                                              className="tdcrusor"
                                              onClick={() => editEmploye(item)}
                                            >
                                              <i class="ri-pencil-fill"></i>
                                            </td>
                                            <td
                                              className="tdcrusor"
                                              onClick={() =>
                                                deleteEmploye(item)
                                              }
                                            >
                                              <i class="ri-delete-bin-5-line text-danger"></i>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colspan="12"
                                          className="text-center"
                                        >
                                          <img
                                            src="https://res.cloudinary.com/dpnvbcrn2/image/upload/v1697629974/Group_3_1_pdpo1e.png"
                                            class="nodata"
                                          />
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {employeData && employeData.length > 0 ? (
                                <Pagination
                                  className="pagination"
                                  itemClass="page-item" // a dd it forbootstrap
                                  linkClass="page-link" // add it for bootstrap 4
                                  activePage={employecurrentPage}
                                  itemsCountPerPage={employerecordPerPage}
                                  totalItemsCount={employetotalPage}
                                  pageRangeDisplayed={employepageRange}
                                  onChange={employehandlePageChange}
                                />
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </>
    </>
  );
};

export default Home;
