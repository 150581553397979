import React from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//componentes
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer/Footer";
const Home = ({ title }) => {
  const navigate = useNavigate();

  const navigateToDashbaord = () => {
    // 👇️ navigate to /contacts
    navigate("/dashboard");
  };

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/notification");
  };
  return (
    <>
      <>
        <div className="card_dash header_after">
          <div>
            <h1>{title}</h1>
          </div>
          <div className="profile_icon_logo">
        
            <div className="prifile_icons" onClick={navigateToDashbaord}>
              <i class="ri-user-fill"></i>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Home;
