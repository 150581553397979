import React from "react";
//rotas
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import History from "./pages/History";
import Portfollio from "./pages/Portfollio";
import Employeform from "./pages/employeform"
function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />{" "}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/history" element={<History />} />
          <Route path="/portfolio" element={<Portfollio />} />
          <Route path="/employeform" element={<Employeform />} />
          <Route path="/employeform/:id" element={<Employeform />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
