const service = {
  submit_Employee_details:"adminapi/submit_Employee_details",
  get_employe_details:"adminapi/get_employe_details",
  delete_employe_details:"adminapi/delete_employe_details",
  getedit_employe_details:"adminapi/getedit_employe_details",
  edit_employe_details:"adminapi/edit_employe_details",
  login:"adminapi/login",
  login_history_hr:"adminapi/login_history_hr",
  employe_details:"adminapi/employe_details",
  search_employe_details:"adminapi/search_employe_details",
};
export default service;
