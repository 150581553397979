import React, { useEffect } from "react";
import useState from "react-usestateref";
import Button from "@mui/material/Button";
import "semantic-ui-css/semantic.min.css";
import Sidebar from "./Sidebar";
import AfterloginHeader from "./AfterloginHeader";
import { toast } from "react-toastify";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Pagination from "react-js-pagination";
import useStateRef from "react-usestateref";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
const Department = [
  { key: "Developement", label: "Developement", value: "Developement" },
  { key: "Designer", label: "Designer", value: "Designer" },
  { key: "Human resource management", label: "Human resource management", value: "Human resource management" },
  {
    key: "Digital Marketting",
    label: "Digital Marketting",
    value: "Digital Marketting",
  },
  { key: "MobileApp", label: "MobileApp", value: "MobileApp" },
  { key: "Chiefs", label: "Chiefs", value: "Chiefs" },
  { key: "Director", label: "Director", value: "Director" },
  {
    key: "Bussiness Excutivers",
    label: "Bussiness Excutivers",
    value: "Bussiness Excutivers",
  },
];
const Employeform = () => {
  useEffect(() => {
    // var id = window.location.href.split("/").at(-1);
    var id = window.location.href.split("/").at(4);

    console.log(id);
    if (id != null && id != undefined && id != "") {
      geteditdetails(id);
      setEditStatus(true);
    } else {
      setEditStatus(false);
    }
  }, []);

  const inputvalues = {
    employeName: "",
    fatherName: "",
    dateofBirtth: "",
    bloodGroup: "",
    dateofJoin: "",
    dateofleave: "",
    gender: "",
    employId: "",
    salary: "",
    personalMail: "",
    officialMail: "",
    phoneNumber: "",
    emergencyNumber: "",
    address: "",
    city: "",
    state: "",
    country: "",
    leavingReason: "",
  };
  const [formValue, setFormValue] = useState(inputvalues);

  const {
    employeName,
    fatherName,
    dateofBirtth,
    bloodGroup,
    dateofJoin,
    dateofleave,
    employId,
    salary,
    personalMail,
    officialMail,
    phoneNumber,
    emergencyNumber,
    address,
    leavingReason,
  } = formValue;
  const [employeNameErr, setemployeNameErr, setemployeNameErrref] =
    useState(false);
  const [fatherNameErr, setfatherNameErr, setfatherNameErrref] =
    useState(false);
  const [dateofBirtthErr, setdateofBirtthErr, setdateofBirtthErrref] =
    useState(false);
  const [bloodGroupErr, setbloodGroupErr, setbloodGroupErrref] =
    useState(false);
  const [dateofJoinErr, setdateofJoinErr, setdateofJoinErrref] =
    useState(false);
  const [dateofleaveErr, setdateofleaveErr, setdateofleaveErrref] =
    useState(false);
  const [employIdErr, setemployIdErr, setemployIdErrref] = useState(false);
  const [roleErr, setroleErr, setroleErrref] = useState(false);
  const [salaryErr, setsalaryErr, setsalaryErrref] = useState(false);
  const [personalMailErr, setpersonalMailErr, setpersonalMailErrref] =
    useState(false);
  const [officialMailErr, setofficialMailErr, setofficialMailErrref] =
    useState(false);
  const [phoneNumberErr, setphoneNumberErr, setphoneNumberErrref] =
    useState(false);
  const [emergencyNumberErr, setemergencyNumberErr, setemergencyNumberErrref] =
    useState(false);
  const [addressErr, setaddressErr, setaddressErrref] = useState(false);
  const [genderErr, setgenderErr, setgenderErrref] = useState(false);
  const [countryErr, setcountryErr, setcountryErrref] = useState(false);
  const [stateErr, setstateErr, setstateErrref] = useState(false);
  const [cityErr, setcityErr, setcityErrref] = useState(false);
  const [employeStatusErr, setemployeStatusErr, setemployeStatusErrref] =
    useState(false);
  const [leavingReasonErr, setleavingReasonErr, setleavingReasonErrref] =
    useState(false);
  const [formError, setformError, formErrorref] = useStateRef("");
  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);

    validate(formValue);
  };

  const validate = () => {
    try {
      const error = {};
      if (formValue.employeName == "") {
        setemployeNameErr(true);
        error.employeName = "Name is required";
      } else if (formValue.fatherName == "") {
        setfatherNameErr(true);
        error.fatherName = "Father Name is required";
      } else if (formValue.dateofBirtth == "") {
        setdateofBirtthErr(true);
        error.dateofBirtth = "Date Of Birth is required";
      } else if (formValue.bloodGroup == "") {
        setbloodGroupErr(true);
        error.bloodGroup = "blood Group is required";
      } else if (formValue.dateofJoin == "") {
        setdateofJoinErr(true);
        error.dateofJoin = "Date of Join is required";
      } else if (selectedGender == "") {
        setgenderErr(true);
        error.gender = "Gender is required";
      } else if (formValue.employId == "") {
        setemployIdErr(true);
        error.employId = "Employ Id is required";
      } else if (selectedDepartment == "") {
        setroleErr(true);
        error.role = "Role is required";
      } else if (formValue.salary == "") {
        setsalaryErr(true);
        error.salary = "Salary is required";
      } else if (employeStatus == "") {
        setemployeStatusErr(true);
        error.employeStatus = "Employe Status is required";
      } else if (formValue.personalMail == "") {
        setpersonalMailErr(true);
        error.personalMail = "PersonalMail is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.personalMail)
      ) {
        error.personalMail = "Invalid email address";
        setpersonalMailErr(true);
      } else if (formValue.officialMail == "") {
        setofficialMailErr(true);
        error.officialMail = "OfficialMail is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.officialMail)
      ) {
        error.officialMail = "Invalid email address";
        setofficialMailErr(true);
      } else if (formValue.phoneNumber == "") {
        setphoneNumberErr(true);
        error.phoneNumber = "Phone Number is required";
      } else if (formValue.emergencyNumber == "") {
        setemergencyNumberErr(true);
        error.emergencyNumber = "Emergency Number is required";
      } else if (formValue.address == "") {
        setaddressErr(true);
        error.address = "Address is required";
      } else if (formValue.leavingReason == "") {
        setleavingReasonErr(true);
        error.leavingReason = "Leaving Reason is required";
      } else if (selectedCountry == "") {
        setcountryErr(true);
        error.country = "Country is required";
      } else if (selectedState == "") {
        setstateErr(true);
        error.state = "State is required";
      }
      setformError(error);
    } catch (error) {}
  };
  const navigate = useNavigate();
  const Update = async () => {
    validate();
    var id = window.location.href.split("/").at(-1);

    var obj = {
      firstName: formValue.employeName,
      fathersName: formValue.fatherName,
      dateofBirth: formValue.dateofBirtth,
      bloodGroup: formValue.bloodGroup,
      dateofJoin: formValue.dateofJoin,
      dateofLeave: formValue.dateofleave,
      mail: formValue.officialMail,
      personalMail: formValue.personalMail,
      phone: formValue.phoneNumber,
      emergencyPhone: formValue.emergencyNumber,
      address1: formValue.address,
      salary: formValue.salary,
      reasonofresign: formValue.leavingReason,
      employeID: formValue.employId,
      gender: selectedGender,
      country: selectedCountry,
      city: selectedCity,
      state: selectedState,
      department: selectedDepartment,
      status: employeStatus,
      designation: selectedDepartment,
      id: id,
    };
    console.log(obj);
    if (
      formValue.employeName != "" &&
      formValue.fatherName != "" &&
      formValue.dateofBirtth != "" &&
      formValue.bloodGroup != "" &&
      formValue.dateofJoin != "" &&
      formValue.officialMail != "" &&
      formValue.personalMail != "" &&
      formValue.phoneNumber != "" &&
      formValue.emergencyNumber != "" &&
      formValue.address != "" &&
      formValue.salary != "" &&
      formValue.employId != "" &&
      selectedGender != ""
    ) {
      var data = {
        apiUrl: apiService.edit_employe_details,
        payload: obj,
      };
      var resp = await postMethod(data);
      console.log(resp);
      if (resp.status == true) {
        toast.success(resp.message);
        navigate("/history");

        formValue.employeName = "";
        formValue.fatherName = "";
        formValue.dateofBirtth = "";
        formValue.bloodGroup = "";
        formValue.dateofJoin = "";
        formValue.dateofleave = "";
        formValue.officialMail = "";
        formValue.personalMail = "";
        formValue.phoneNumber = "";
        formValue.emergencyNumber = "";
        formValue.address = "";
        formValue.salary = "";
        formValue.leavingReason = "";
        formValue.employId = "";
        setSelectedGender("");
        setSelectedCountry("");
        setSelectedCity("");
        setSelectedState("");
        setselectedDepartment("");
        setEmployeStatus("");
      } else {
        toast.error(resp.message);
      }
    } else {
      toast.error("Invalid Details");
    }
  };
  const submit = async () => {
    validate();
    var obj = {
      firstName: formValue.employeName,
      fathersName: formValue.fatherName,
      dateofBirth: formValue.dateofBirtth,
      bloodGroup: formValue.bloodGroup,
      dateofJoin: formValue.dateofJoin,
      dateofLeave: formValue.dateofleave,
      mail: formValue.officialMail,
      personalMail: formValue.personalMail,
      phone: formValue.phoneNumber,
      emergencyPhone: formValue.emergencyNumber,
      address1: formValue.address,
      salary: formValue.salary,
      reasonofresign: formValue.leavingReason,
      employeID: formValue.employId,
      gender: selectedGender,
      country: selectedCountry,
      city: selectedCity,
      state: selectedState,
      department: selectedDepartment,
      status: employeStatus,
      designation: selectedDepartment,
    };
    console.log(obj);
    if (
      formValue.employeName != "" &&
      formValue.fatherName != "" &&
      formValue.dateofBirtth != "" &&
      formValue.bloodGroup != "" &&
      formValue.dateofJoin != "" &&
      formValue.officialMail != "" &&
      formValue.personalMail != "" &&
      formValue.phoneNumber != "" &&
      formValue.emergencyNumber != "" &&
      formValue.address != "" &&
      formValue.salary != "" &&
      formValue.employId != "" &&
      selectedGender != ""
    ) {
      var data = {
        apiUrl: apiService.submit_Employee_details,
        payload: obj,
      };
      var resp = await postMethod(data);
      console.log(resp);
      if (resp.status == true) {
        toast.success(resp.message);
        navigate("/history");

        formValue.employeName = "";
        formValue.fatherName = "";
        formValue.dateofBirtth = "";
        formValue.bloodGroup = "";
        formValue.dateofJoin = "";
        formValue.dateofleave = "";
        formValue.officialMail = "";
        formValue.personalMail = "";
        formValue.phoneNumber = "";
        formValue.emergencyNumber = "";
        formValue.address = "";
        formValue.salary = "";
        formValue.leavingReason = "";
        formValue.employId = "";
        setSelectedGender("");
        setSelectedCountry("");
        setSelectedCity("");
        setSelectedState("");
        setselectedDepartment("");
        setEmployeStatus("");
      } else {
        toast.error(resp.message);
      }
    } else {
      toast.error("Invalid Details");
    }
  };

  const [editStatus, setEditStatus, editStatusref] = useStateRef(false);
  const geteditdetails = async (id) => {
    var obj = {
      id: id,
    };
    var data = {
      apiUrl: apiService.getedit_employe_details,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp) {
      console.log(resp);
      formValue.employeName = resp.data.firstName;
      formValue.fatherName = resp.data.fathersName;
      formValue.dateofBirtth = resp.data.dateofBirth;
      formValue.bloodGroup = resp.data.bloodGroup;
      formValue.dateofJoin = resp.data.dateofJoin;
      formValue.dateofleave = resp.data.dateofLeave;
      formValue.officialMail = resp.data.mail;
      formValue.personalMail = resp.data.personalMail;
      formValue.phoneNumber = resp.data.phone;
      formValue.emergencyNumber = resp.data.emergencyPhone;
      formValue.address = resp.data.address1;
      formValue.salary = resp.data.salary;
      formValue.leavingReason = resp.data.reasonofresign;
      formValue.employId = resp.data.employeID;
      setSelectedGender(resp.data.gender);
      setSelectedCountry(resp.data.country);
      setSelectedCity(resp.data.city);
      setSelectedState(resp.data.state);
      setselectedDepartment(resp.data.department);
      setEmployeStatus(resp.data.status);
    }
  };
  ///GENDER/////////
  const [selectedGender, setSelectedGender] = useState("");

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
    validate();
  };

  //EMPLOYEE STATUS/////
  const [employeStatus, setEmployeStatus] = useState("");

  const handleEmployeStatus = (event) => {
    setEmployeStatus(event.target.value);
  };
  const [selectedDepartment, setselectedDepartment] = useState("");

  const handleDepartmentChange = (event) => {
    setselectedDepartment(event.target.value);
    validate();
  };
  //CSVC///////
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("state");
  const [selectedCity, setSelectedCity] = useState("city");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ name: firstCountry } = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
      } catch (error) {
        setCountries([]);
      }
    };
    getCountries();
  }, []);
  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };
    getStates();
  }, [selectedCountry]);
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        const [{ name: firstCity = "" } = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
  }, [selectedState]);
  return (
    <>
      <>
        {/* <Navbar /> */}
        <div className="css-1wr4jig">
          <div className="css-1o43t2y">
            <div className="css-1t8cbiy side">
              <Sidebar />
            </div>
            <div className="css-1h690ep content">
              <div className="content_dash_board">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <AfterloginHeader title="EMPLOYE FORM" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="card_dash">
                      <form className="login_form_section p-0 d-block">
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email">Employe Name</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Employe Name"
                                  name="employeName"
                                  value={employeName}
                                  onChange={handleChange}
                                />
                                <div>
                                  {employeNameErr == true ? (
                                    <div>
                                      <p style={{ color: "red" }}>
                                        {formErrorref.current.employeName}
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Father's Name</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="FathersName"
                                  name="fatherName"
                                  value={fatherName}
                                  onChange={handleChange}
                                />
                                <div>
                                  {fatherNameErr == true ? (
                                    <div>
                                      <p style={{ color: "red" }}>
                                        {formErrorref.current.fatherName}
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email">Date Of Birth</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Date Of Birth"
                                  name="dateofBirtth"
                                  value={dateofBirtth}
                                  onChange={handleChange}
                                />
                                {dateofBirtthErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.dateofBirtth}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Blood Group</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Blood Group"
                                  name="bloodGroup"
                                  value={bloodGroup}
                                  onChange={handleChange}
                                />
                                {bloodGroupErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.bloodGroup}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email">Date Of Join</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Date of Join"
                                  name="dateofJoin"
                                  value={dateofJoin}
                                  onChange={handleChange}
                                />
                                {dateofJoinErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.dateofJoin}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Date of Leave</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Date of Leave"
                                  name="dateofleave"
                                  value={dateofleave}
                                  onChange={handleChange}
                                />
                                {dateofleaveErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.dateofleave}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email">Gender</label>
                              <div className="copy_icon">
                                <select
                                  id="input-gender"
                                  name="gender"
                                  value={selectedGender}
                                  className="form-control form-control-lg"
                                  onChange={handleGenderChange}
                                >
                                  <option value="" disabled>
                                    Select Gender
                                  </option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                                {genderErr === true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.gender}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Designation</label>
                              <div className="copy_icon">
                                <select
                                  id="input-gender"
                                  name="designation"
                                  placeholder="Designation"
                                  value={selectedDepartment}
                                  className="form-control form-control-lg"
                                  onChange={handleDepartmentChange}
                                >
                                  <option value="" disabled>
                                    Select Deparment
                                  </option>
                                  {Department.map((dept) => (
                                    <option key={dept.key} value={dept.value}>
                                      {dept.label}
                                    </option>
                                  ))}
                                </select>
                                {roleErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.role}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email">Address</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Home Address"
                                  name="address"
                                  value={address}
                                  onChange={handleChange}
                                />
                                {addressErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.address}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Salary</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Salary"
                                  name="salary"
                                  value={salary}
                                  onChange={handleChange}
                                />
                                {salaryErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.salary}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email"> Employe Status</label>
                              <div className="copy_icon">
                                <select
                                  id="input-gender"
                                  className="form-control form-control-lg"
                                  name="employestatus"
                                  value={employeStatus}
                                  onChange={handleEmployeStatus}
                                >
                                  <option value="">Choose</option>
                                  <option value="Active">Active</option>
                                  <option value="DeActive">DeActive</option>
                                </select>
                                {employeStatusErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.employeStatus}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Country</label>
                              <div className="copy_icon">
                                <select
                                  className="form-control form-control-lg"
                                  name="Country"
                                  value={selectedCountry}
                                  onChange={(event) =>
                                    setSelectedCountry(event.target.value)
                                  }
                                >
                                  {countries.length > 0 ? (
                                    countries.map(({ isoCode, name }) => (
                                      <option
                                        className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                        value={isoCode}
                                        key={isoCode}
                                      >
                                        {name}
                                      </option>
                                    ))
                                  ) : (
                                    <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                      No Countries Found!
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email"> City</label>
                              <div className="copy_icon">
                                <select
                                  className="form-control form-control-lg"
                                  name="city"
                                  value={selectedCity}
                                  onChange={(event) =>
                                    setSelectedCity(event.target.value)
                                  }
                                >
                                  {cities.length > 0 ? (
                                    cities.map(({ isoCode, name }) => (
                                      <option
                                        className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                        value={isoCode}
                                        key={isoCode}
                                      >
                                        {name}
                                      </option>
                                    ))
                                  ) : (
                                    <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                      No Cities Found!
                                    </option>
                                  )}
                                </select>
                                {cityErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.city}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">State</label>
                              <div className="copy_icon">
                                <select
                                  className="form-control form-control-lg"
                                  name="state"
                                  value={selectedState}
                                  onChange={(event) =>
                                    setSelectedState(event.target.value)
                                  }
                                >
                                  {states.length > 0 ? (
                                    states.map(({ isoCode, name }) => (
                                      <option
                                        className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                        value={isoCode}
                                        key={isoCode}
                                      >
                                        {name}
                                      </option>
                                    ))
                                  ) : (
                                    <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                      No States Found!
                                    </option>
                                  )}
                                </select>
                                {stateErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.state}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email"> Phone Number</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="phoneNumber"
                                  name="phoneNumber"
                                  value={phoneNumber}
                                  onChange={handleChange}
                                />
                                {phoneNumberErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.phoneNumber}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Emergency Number</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Emergency Number"
                                  name="emergencyNumber"
                                  value={emergencyNumber}
                                  onChange={handleChange}
                                />
                                {emergencyNumberErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.emergencyNumber}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email"> Official Mail</label>
                              <div className="copy_icon">
                                <input
                                  type="email"
                                  class="form-control"
                                  placeholder="Official Mail"
                                  name="officialMail"
                                  value={officialMail}
                                  onChange={handleChange}
                                />
                                <div>
                                  {officialMailErr == true ? (
                                    <div>
                                      <p style={{ color: "red" }}>
                                        {formErrorref.current.officialMail}
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Personal Mail</label>
                              <div className="copy_icon">
                                <input
                                  type="email"
                                  class="form-control"
                                  placeholder="Personal Mail"
                                  name="personalMail"
                                  value={personalMail}
                                  onChange={handleChange}
                                />
                                <div>
                                  {phoneNumberErr == true ? (
                                    <div>
                                      <p style={{ color: "red" }}>
                                        {formErrorref.current.phoneNumber}
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <label for="email"> Employ Id</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Employ Id"
                                  name="employId"
                                  value={employId}
                                  onChange={handleChange}
                                />
                                <div>
                                  {employIdErr == true ? (
                                    <div>
                                      <p style={{ color: "red" }}>
                                        {formErrorref.current.employeId}
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label for="email">Leaving Reason</label>
                              <div className="copy_icon">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Leaving  Reason"
                                  name="leavingReason"
                                  value={leavingReason}
                                  onChange={handleChange}
                                />
                                {leavingReasonErr == true ? (
                                  <div>
                                    <p style={{ color: "red" }}>
                                      {formErrorref.current.leavingReason}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {editStatusref.current == true ? (
                          <div className="button_support">
                            <Button onClick={Update}>Update</Button>
                          </div>
                        ) : (
                          <div className="button_support">
                            <Button onClick={submit}>Submit</Button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </>
    </>
  );
};

export default Employeform;
